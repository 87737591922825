<template>
      <section class="section has-margin">
        <div class="db-news wrapper">
          <div class="grid">
            <div class="grid__item landscape--one-third portable--one-half ">
              <div class="text-block-action-with-action">
                <h2 class="h2">Inside SunriseCargo</h2>
                <div><p>
                  La red incomparable de Sunrise Cargo nos brinda una presencia local, regional y global en casi todos los mercados comerciales. Durante más de 25 años, hemos proporcionado soluciones logísticas innovadoras para todos los modos de transporte. Lo invitamos a conocer más sobre nuestro negocio explorando nuestras últimas noticias
                </p></div>
                <a href="https://noticias.sunrisecargo.com/" target="_blank" class="btn btn--border btn--default hide show-portable " tabindex="">
                  <span>Ver más</span>
                </a>
              </div>
            </div>

            <div class="grid__item landscape--two-thirds portable--one-half">
                <div class="wrapper__reset-spacing--for-small">
                    <div class="image-text-block-overlay">
                        <a class="news-large-picture news-link-picture" href="https://noticias.sunrisecargo.com/?p=11192" target="_blank" title="Organización Marítima Internacional establece prioridades clave para 2025">
                            <picture class="">
                                <source media="(max-width: 380px)" srcset="/imagenes/NOTICIAS/noticia-home.png">
                                <source media="(max-width: 430px)" srcset="/imagenes/NOTICIAS/noticia-home.png">
                                <source media="(max-width: 570px)" srcset="/imagenes/NOTICIAS/noticia-home.png">
                                <source media="(max-width: 767px)" srcset="/imagenes/NOTICIAS/noticia-home.png">
                                <source media="(max-width: 925px)" srcset="/imagenes/NOTICIAS/noticia-home.png">
                                <source media="(max-width: 1023px)" srcset="/imagenes/NOTICIAS/noticia-home.png">
                                <source media="(max-width: 1100px)" srcset="/imagenes/NOTICIAS/noticia-home.png">
                                <img src="/imagenes/NOTICIAS/noticia-home.png" alt="Noticia DB Schenker" class="image-text-block-overlay__photo">
                            </picture>
                            <div class="text-block-intro image-text-block-intro__text-block text-block-intro--background-blue image-text-block-overlay__text-block">
                                <time class="text-block-intro__small small" datetime="2025-01-20">
                                Noticias, Enero 20-2025 / Sunrise Cargo
                                </time>
                                <h3 class="h4 text-block-intro__title" data-truncate-height="48" style="">Organización Marítima Internacional establece prioridades clave para 2025 </h3>
                                <div>
                                    <p>Flota en las sombras, Mar Rojo y descarbonización resaltan como aristas principales....................... </p>
                                </div>
                                <div href="/imagenes/NOTICIAS/noticia-home.png" class="lk lk--default lk-effect " tabindex="">
                                    <svg class="icon icon--arrow-right-thick icon--svg " viewBox="0 0 129 200" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M28.993 199.404l99.702-99.702L28.993 0 .709 28.285l71.418 71.417L.709 171.119z"></path>
                                    </svg>
                                    <i class="visuallyhidden">Sunrise Cargo SAS </i>
                                    <span>Leer más</span>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
          </div>
        </div> 
      </section>
</template>

<script>
export default {

}
</script>

<style>

</style>